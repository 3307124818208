
import React from "react";
import mapui from "../../assets/img/mapui.svg";
import flag_usa from "../../assets/img/usa_flag.svg";
import flag_ire from "../../assets/img/ire_flag.svg";
import { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

function ActiveUser(props) {
  const mapMarkers = props.activeLocations.allActiveLocations?.map(
    (ele, index) => (
      <Marker
        key={index}
        longitude={parseFloat(ele.coordinates[0])}
        latitude={parseFloat(ele.coordinates[1])}
        color="#009FE3"
      />
    )
  );

  return (
    <section className={props.class}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4>
            Satisfied Users <span>Worldwide</span>
            </h4>
            <p>
            Our commitment to excellence ensures happy customers across the globe, fostering trust, satisfaction, and long-term partnerships.

            </p>
          </div>

          <div className="col-md-12">
            <div className="mapui_box">
              <img className="w-100" alt="map" src={mapui} />

              {/* map bubble pin */}
              <div className="country_spot country_one"></div>
              <div className="map_hover_box country_one">
                <div className="flagbox">
                  <img alt="flagicon" src={flag_usa} />
                </div>
                <div style={{marginLeft: '12px'}}>
                  <div className="countryname">USA</div>
                  {/* <div className="usernum">3795 Correia Drive Zephyrhills, Florida 33542</div> */}
                </div>
              </div>
              
              <div className="country_spot country_two"></div>
              <div className="map_hover_box country_two">
                <div className="flagbox">
                  <img alt="flagicon" src={flag_ire} />
                </div>
                <div style={{marginLeft: '12px'}}>
                  <div className="countryname">Ireland</div>
                  {/* <div className="usernum">Wexford Office & Business Park Whitemill Industrial Estate Townparks, Wexford Town Wexford, Ireland</div> */}
                </div>
              </div>

              {/* <div className="country_spot country_three"></div>
              <div className="map_hover_box country_three">
                <div className="flagbox">
                  <img alt="flagicon" src={flag} />
                </div>
                <div style={{marginLeft: '12px'}}>
                  <div className="countryname">UK</div>
                  <div className="usernum">1,000 users</div>
                </div>
              </div>

              <div className="country_spot country_four"></div>
              <div className="map_hover_box country_four">
                <div className="flagbox">
                  <img alt="flagicon" src={flag} />
                </div>
                <div style={{marginLeft: '12px'}}>
                  <div className="countryname">UK</div>
                  <div className="usernum">1,000 users</div>
                </div>
              </div>

              <div className="country_spot country_five"></div>
              <div className="map_hover_box country_five">
                <div className="flagbox">
                  <img alt="flagicon" src={flag} />
                </div>
                <div style={{marginLeft: '12px'}}>
                  <div className="countryname">UK</div>
                  <div className="usernum">1,000 users</div>
                </div>
              </div>

              <div className="country_spot country_six"></div>
              <div className="map_hover_box country_six">
                <div className="flagbox">
                  <img alt="flagicon" src={flag} />
                </div>
                <div style={{marginLeft: '12px'}}>
                  <div className="countryname">UK</div>
                  <div className="usernum">1,000 users</div>
                </div>
              </div>

              <div className="country_spot country_seven"></div>
              <div className="map_hover_box country_seven">
                <div className="flagbox">
                  <img alt="flagicon" src={flag} />
                </div>
                <div style={{marginLeft: '12px'}}>
                  <div className="countryname">UK</div>
                  <div className="usernum">1,000 users</div>
                </div>
              </div>

              <div className="country_spot country_eight"></div>
              <div className="map_hover_box country_eight">
                <div className="flagbox">
                  <img alt="flagicon" src={flag} />
                </div>
                <div style={{marginLeft: '12px'}}>
                  <div className="countryname">UK</div>
                  <div className="usernum">1,000 users</div>
                </div>
              </div>

              <div className="country_spot country_nine"></div>
              <div className="map_hover_box country_nine">
                <div className="flagbox">
                  <img alt="flagicon" src={flag} />
                </div>
                <div style={{marginLeft: '12px'}}>
                  <div className="countryname">UK</div>
                  <div className="usernum">1,000 users</div>
                </div>
              </div>

              <div className="country_spot country_ten"></div>
              <div className="map_hover_box country_ten">
                <div className="flagbox">
                  <img alt="flagicon" src={flag} />
                </div>
                <div style={{marginLeft: '12px'}}>
                  <div className="countryname">UK</div>
                  <div className="usernum">1,000 users</div>
                </div>
              </div>

              <div className="country_spot country_eleven"></div>
              <div className="map_hover_box country_eleven">
                <div className="flagbox">
                  <img alt="flagicon" src={flag} />
                </div>
                <div style={{marginLeft: '12px'}}>
                  <div className="countryname">UK</div>
                  <div className="usernum">1,000 users</div>
                </div>
              </div>

              <div className="country_spot country_twelve"></div>
              <div className="map_hover_box country_twelve">
                <div className="flagbox">
                  <img alt="flagicon" src={flag} />
                </div>
                <div style={{marginLeft: '12px'}}>
                  <div className="countryname">UK</div>
                  <div className="usernum">1,000 users</div>
                </div>
              </div>

              <div className="country_spot country_thirteen"></div>
              <div className="map_hover_box country_thirteen">
                <div className="flagbox">
                  <img alt="flagicon" src={flag} />
                </div>
                <div style={{marginLeft: '12px'}}>
                  <div className="countryname">UK</div>
                  <div className="usernum">1,000 users</div>
                </div>
              </div> */}


            </div>
          </div>

          

          
          {/* <Map
            mapLib={import("mapbox-gl")}
            initialViewState={{
              zoom: 0.8,
              scrollZoom: false,
              boxZoom: false,
              doubleClickZoom: false,
              dragRotate : false,
              latitude: 20.39720860778328,
              longitude: 8.207785285538904,
              bearing: 0,
              pitch: 0,
            }}
            dragRotate={false}
            touchZoomRotate={false}
            dragPan={false}
            projection={"mercator"}
            style={{
              width: "100%",
              height: 550,
              borderRadius: "40px",
              margin: 0,
              padding: 0,
              overflow: "hidden",
            }}
            mapStyle="mapbox://styles/mapbox/light-v11"
          >
            {mapMarkers}
          </Map> */}
        </div>
      </div>
    </section>
  );
}

export default ActiveUser;

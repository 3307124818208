import React from "react";
import "react-multi-carousel/lib/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Partner(props) {
  const { partnersData } = props;

  return (
    <section className={props.class}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="myCard">
              <div className="row">
                <div className="col-md-12 col-lg-4 col-xl-4 mb-4 mb-lg-0">
                  <h2>Partner with</h2>
                  <h2>Prestigious Brands</h2>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-8">
                  <Swiper
                    slidesPerView={4}
                    spaceBetween={30}
                    loop={true}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {partnersData?.map((partner) => (
                      <SwiperSlide>
                        {partner?.logo?.includes("http") ? (
                          <img alt="icon"
                            style={{
                              maxHeight: "100px",
                              maxWidth: "200px",
                              objectFit: "cover",
                              marginRight: "10px",
                            }}
                            src={partner?.logo}
                            key={partner?._id}
                          />
                        ) : (
                          ""
                        )}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partner;
